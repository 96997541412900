<template>
    <v-card outlined>
        <v-card-title class="pl-8 block-title">영업종료 <span style="margin-left: 10px; font-size: 17px; font-weight: normal; color: #919191;">(영업종료를 안내하는 멘트)</span></v-card-title>
        <v-card-text class="mt-3 mb-n8">
            <v-row>
                <v-col>
                    <v-textarea outlined placeholder="영업종료 문구를 입력해 주세요" v-model="workOffMent"></v-textarea>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'svc_voice_intro_work_off_ment',
    components: {},
    data: () => ({
        workOffMent: '지금은 영업이 종료되었습니다. 나중에 다시 전화주시기 바랍니다.',
        debounceTimer: undefined
    }),
    computed: {
        ...mapState('svcVoiceIntro', {
            stateWorkOffMent: state => state.workOffMent
        })
    },
    watch: {
        workOffMent() {
            this.storeMent()
        }
    },
    created() {
    },
    mounted() {
        this.reload()
    },
    methods: {
        reload() {
            this.workOffMent = this.stateWorkOffMent
        },
        apply() {
            this.$store.commit('svcVoiceIntro/setWorkOffMent', this.workOffMent)
        },
        storeMent() {
            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer)
            }

            this.debounceTimer = setTimeout(() => {
                this.$store.commit('svcVoiceIntro/setWorkOffMent', this.workOffMent)
            }, 100)
        }
    }
}
</script>

